// ---------- Variables ----------  //
// ---------- fontweight ----------  //
// $w_thin: 100;
// $w_extra-light: 200;
$w_light: 300;
$w_regular: 400;
// $w_medium: 500;
$w_semi-bold: 600;
$w_bold: 700;
$w_extra-bold: 800;
$w_black: 900;
// ---------- fontweight ----------  //
// ---------- color ----------  //
$c_black:#212121;
$c_orange:#ffa800;
// ---------- End Variables ----------  //
$bdrs:5px;