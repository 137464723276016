@import 'variables';
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.icon {
  display: inline-block;
}

.hide{
  display: none;
}
.noValid{
  border: 1px solid red!important;
}
.wrapper{
  overflow: hidden;
}