@import 'variables';
.formbox {
  max-width: 390px;
  text-align: center;
  &--title {
    font-size: 22px;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 10px;
    span {
      font-weight: $w_extra-bold;
      color: $c_orange;
    }
  }
  &--text {
    font-size: 16px;
    color: #fff;
  }
  &--item {
    padding-top: 20px;
  }
  label {
    display: block;
    text-align: left;
    padding-left: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: $w_bold;
    padding-bottom: 10px;
  }
  .privacypolicy {
    margin-top: 30px;
    font-size: 14px;
    color: #fff;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea {
    font-family: 'proxima_nova';
    font-size: 16px;
    height: 50px;
    padding-left: 14px;
    border-radius: $bdrs;
    width: 100%;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0 5px 20px rgba(#000, .1);
  }
  textarea{
    padding-top: 10px;
    height: 70px;
    resize: none;
  }
  .btn {
    display: block;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    padding: 0;
    width: 100%;
    height: 70px;
    line-height: 70px;
    border-radius: 180px;
    background: #fcc300;
    background: -moz-linear-gradient(top, #fcc300 0%, #ffae00 100%);
    background: -webkit-linear-gradient(top, #fcc300 0%, #ffae00 100%);
    background: linear-gradient(to bottom, #fcc300 0%, #ffae00 100%);
    font-weight: $w_bold;
    color: $c_black;
    font-size: 18px;
    text-transform: uppercase;
    text-shadow: 0 1px rgba(#fff, 0.4);
    box-shadow: 0 5px 20px rgba(#000, .1);
    transition-duration: .3s;
    &:hover {
      background: #ffae00;
      background: -moz-linear-gradient(top, #ffae00 0%, #fcc300 100%);
      background: -webkit-linear-gradient(top, #ffae00 0%, #fcc300 100%);
      background: linear-gradient(to bottom, #ffae00 0%, #fcc300 100%);
    }
  }
}

.btn {
  display: block;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  padding: 0;
  width: 100%;
  height: 70px;
  line-height: 70px;
  border-radius: 180px;
  background: #fcc300;
  background: -moz-linear-gradient(top, #fcc300 0%, #ffae00 100%);
  background: -webkit-linear-gradient(top, #fcc300 0%, #ffae00 100%);
  background: linear-gradient(to bottom, #fcc300 0%, #ffae00 100%);
  font-weight: $w_bold;
  color: $c_black;
  font-size: 18px;
  text-transform: uppercase;
  text-shadow: 0 1px rgba(#fff, 0.4);
  box-shadow: 0 5px 20px rgba(#000, .1);
  transition-duration: .3s;
  &-sm {
    height: 55px;
    line-height: 55px;
  }
  &:hover {
    background: #ffae00;
    background: -moz-linear-gradient(top, #ffae00 0%, #fcc300 100%);
    background: -webkit-linear-gradient(top, #ffae00 0%, #fcc300 100%);
    background: linear-gradient(to bottom, #ffae00 0%, #fcc300 100%);
  }
}