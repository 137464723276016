// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-smile1-name: 'icon-smile1';
$icon-smile1-x: 0px;
$icon-smile1-y: 58px;
$icon-smile1-offset-x: 0px;
$icon-smile1-offset-y: -58px;
$icon-smile1-width: 50px;
$icon-smile1-height: 50px;
$icon-smile1-total-width: 165px;
$icon-smile1-total-height: 108px;
$icon-smile1-image: '../img/sprite.png';
$icon-smile1: (0px, 58px, 0px, -58px, 50px, 50px, 165px, 108px, '../img/sprite.png', 'icon-smile1', );
$icon-smile2-name: 'icon-smile2';
$icon-smile2-x: 116px;
$icon-smile2-y: 0px;
$icon-smile2-offset-x: -116px;
$icon-smile2-offset-y: 0px;
$icon-smile2-width: 49px;
$icon-smile2-height: 50px;
$icon-smile2-total-width: 165px;
$icon-smile2-total-height: 108px;
$icon-smile2-image: '../img/sprite.png';
$icon-smile2: (116px, 0px, -116px, 0px, 49px, 50px, 165px, 108px, '../img/sprite.png', 'icon-smile2', );
$icon-smile3-name: 'icon-smile3';
$icon-smile3-x: 50px;
$icon-smile3-y: 58px;
$icon-smile3-offset-x: -50px;
$icon-smile3-offset-y: -58px;
$icon-smile3-width: 50px;
$icon-smile3-height: 50px;
$icon-smile3-total-width: 165px;
$icon-smile3-total-height: 108px;
$icon-smile3-image: '../img/sprite.png';
$icon-smile3: (50px, 58px, -50px, -58px, 50px, 50px, 165px, 108px, '../img/sprite.png', 'icon-smile3', );
$icon-smile-bad-name: 'icon-smileBad';
$icon-smile-bad-x: 0px;
$icon-smile-bad-y: 0px;
$icon-smile-bad-offset-x: 0px;
$icon-smile-bad-offset-y: 0px;
$icon-smile-bad-width: 58px;
$icon-smile-bad-height: 58px;
$icon-smile-bad-total-width: 165px;
$icon-smile-bad-total-height: 108px;
$icon-smile-bad-image: '../img/sprite.png';
$icon-smile-bad: (0px, 0px, 0px, 0px, 58px, 58px, 165px, 108px, '../img/sprite.png', 'icon-smileBad', );
$icon-smile-good-name: 'icon-smileGood';
$icon-smile-good-x: 58px;
$icon-smile-good-y: 0px;
$icon-smile-good-offset-x: -58px;
$icon-smile-good-offset-y: 0px;
$icon-smile-good-width: 58px;
$icon-smile-good-height: 58px;
$icon-smile-good-total-width: 165px;
$icon-smile-good-total-height: 108px;
$icon-smile-good-image: '../img/sprite.png';
$icon-smile-good: (58px, 0px, -58px, 0px, 58px, 58px, 165px, 108px, '../img/sprite.png', 'icon-smileGood', );
$icon-smile1-2x-name: 'icon-smile1@2x';
$icon-smile1-2x-x: 0px;
$icon-smile1-2x-y: 116px;
$icon-smile1-2x-offset-x: 0px;
$icon-smile1-2x-offset-y: -116px;
$icon-smile1-2x-width: 100px;
$icon-smile1-2x-height: 100px;
$icon-smile1-2x-total-width: 330px;
$icon-smile1-2x-total-height: 216px;
$icon-smile1-2x-image: '../img/sprite@2x.png';
$icon-smile1-2x: (0px, 116px, 0px, -116px, 100px, 100px, 330px, 216px, '../img/sprite@2x.png', 'icon-smile1@2x', );
$icon-smile2-2x-name: 'icon-smile2@2x';
$icon-smile2-2x-x: 232px;
$icon-smile2-2x-y: 0px;
$icon-smile2-2x-offset-x: -232px;
$icon-smile2-2x-offset-y: 0px;
$icon-smile2-2x-width: 98px;
$icon-smile2-2x-height: 100px;
$icon-smile2-2x-total-width: 330px;
$icon-smile2-2x-total-height: 216px;
$icon-smile2-2x-image: '../img/sprite@2x.png';
$icon-smile2-2x: (232px, 0px, -232px, 0px, 98px, 100px, 330px, 216px, '../img/sprite@2x.png', 'icon-smile2@2x', );
$icon-smile3-2x-name: 'icon-smile3@2x';
$icon-smile3-2x-x: 100px;
$icon-smile3-2x-y: 116px;
$icon-smile3-2x-offset-x: -100px;
$icon-smile3-2x-offset-y: -116px;
$icon-smile3-2x-width: 100px;
$icon-smile3-2x-height: 100px;
$icon-smile3-2x-total-width: 330px;
$icon-smile3-2x-total-height: 216px;
$icon-smile3-2x-image: '../img/sprite@2x.png';
$icon-smile3-2x: (100px, 116px, -100px, -116px, 100px, 100px, 330px, 216px, '../img/sprite@2x.png', 'icon-smile3@2x', );
$icon-smile-bad-2x-name: 'icon-smileBad@2x';
$icon-smile-bad-2x-x: 0px;
$icon-smile-bad-2x-y: 0px;
$icon-smile-bad-2x-offset-x: 0px;
$icon-smile-bad-2x-offset-y: 0px;
$icon-smile-bad-2x-width: 116px;
$icon-smile-bad-2x-height: 116px;
$icon-smile-bad-2x-total-width: 330px;
$icon-smile-bad-2x-total-height: 216px;
$icon-smile-bad-2x-image: '../img/sprite@2x.png';
$icon-smile-bad-2x: (0px, 0px, 0px, 0px, 116px, 116px, 330px, 216px, '../img/sprite@2x.png', 'icon-smileBad@2x', );
$icon-smile-good-2x-name: 'icon-smileGood@2x';
$icon-smile-good-2x-x: 116px;
$icon-smile-good-2x-y: 0px;
$icon-smile-good-2x-offset-x: -116px;
$icon-smile-good-2x-offset-y: 0px;
$icon-smile-good-2x-width: 116px;
$icon-smile-good-2x-height: 116px;
$icon-smile-good-2x-total-width: 330px;
$icon-smile-good-2x-total-height: 216px;
$icon-smile-good-2x-image: '../img/sprite@2x.png';
$icon-smile-good-2x: (116px, 0px, -116px, 0px, 116px, 116px, 330px, 216px, '../img/sprite@2x.png', 'icon-smileGood@2x', );
$spritesheet-width: 165px;
$spritesheet-height: 108px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($icon-smile1, $icon-smile2, $icon-smile3, $icon-smile-bad, $icon-smile-good, );
$spritesheet: (165px, 108px, '../img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 330px;
$retina-spritesheet-height: 216px;
$retina-spritesheet-image: '../img/sprite@2x.png';
$retina-spritesheet-sprites: ($icon-smile1-2x, $icon-smile2-2x, $icon-smile3-2x, $icon-smile-bad-2x, $icon-smile-good-2x, );
$retina-spritesheet: (330px, 216px, '../img/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$icon-smile1-group-name: 'icon-smile1';
$icon-smile1-group: ('icon-smile1', $icon-smile1, $icon-smile1-2x, );
$icon-smile2-group-name: 'icon-smile2';
$icon-smile2-group: ('icon-smile2', $icon-smile2, $icon-smile2-2x, );
$icon-smile3-group-name: 'icon-smile3';
$icon-smile3-group: ('icon-smile3', $icon-smile3, $icon-smile3-2x, );
$icon-smile-bad-group-name: 'icon-smileBad';
$icon-smile-bad-group: ('icon-smileBad', $icon-smile-bad, $icon-smile-bad-2x, );
$icon-smile-good-group-name: 'icon-smileGood';
$icon-smile-good-group: ('icon-smileGood', $icon-smile-good, $icon-smile-good-2x, );
$retina-groups: ($icon-smile1-group, $icon-smile2-group, $icon-smile3-group, $icon-smile-bad-group, $icon-smile-good-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
