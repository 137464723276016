/*
* Grid
*/

@import 'susy';
$susy: ( 'columns': susy-repeat(12),
'gutters': 0,
'spread': 'narrow',
'container-spread': 'narrow');


/*
* Grid loop
*/

$min-width-lg:1201px;
$max-width-md:1200px;
$min-width-md:993px;
$max-width-sm:992px;
$min-width-sm:769px;
$max-width-xs:768px;


@for $i from 1 through 12 {
  .col-lg-#{$i},
  .col-md-#{$i},
  .col-sm-#{$i},
  .col-xs-#{$i} {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    float: left;
  }
  .pre-lg-#{$i},
  .pre-md-#{$i},
  .pre-sm-#{$i},
  .pre-xs-#{$i} {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    float: left;
  }
  .col-lg-#{$i} {
    @media screen and (min-width: $min-width-lg) {
      width: span($i);
    }
  }
  .col-md-#{$i} {
    @media screen and (min-width: $min-width-md) and (max-width: $max-width-md) {
      width: span($i);
    }
  }
  .col-sm-#{$i} {
    @media screen and (min-width: $min-width-sm) and (max-width: $max-width-sm) {
      width: span($i);
    }
  }
  .col-xs-#{$i} {
    @media screen and (max-width: $max-width-xs) {
      width: span($i);
    }
  }
  .pre-lg-#{$i} {
    @media screen and (min-width: $min-width-lg) {
      margin-left: span($i);
    }
  }
  .pre-md-#{$i} {
    @media screen and (min-width: $min-width-md) and (max-width: $max-width-md) {
      margin-left: span($i);
    }
  }
  .pre-sm-#{$i} {
    @media screen and (min-width: $min-width-sm) and (max-width: $max-width-sm) {
      margin-left: span($i);
    }
  }
  .pre-xs-#{$i} {
    @media screen and (max-width: $max-width-xs) {
      margin-left: span($i);
    }
  }
}

.row:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
}
.container{
  margin: auto;
  max-width: 1170px;
}