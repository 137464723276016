@import 'reset';
@import 'grid';
@import 'fonts.min.css';
@import 'sprite';
@include retina-sprites($retina-groups);
@include sprites($spritesheet-sprites);
@import 'variables';
@import 'components';
@import 'forms';
//
@import 'magnifick.min.css';
@import 'slick.min.css';
@import 'jquery-ui.min.css';

body {
  font-family: 'proxima_nova';
  font-size: 16px;
  line-height: 1.3;
  color: $c_black;
}

.psd {
  &--img {
    opacity: .5;
    width: 100%;
    position: absolute;
    left: 0;
    top: 9px;
    background: url(../img/index_v3.jpg) 50% 0 no-repeat;
    z-index: 50;
    height: 11750px;
    pointer-events: none;

    &.hide {
      display: none;
    }

    &.invert {
      -webkit-filter: invert(1);
    }
  }

  &--hide {
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 50%;
    color: #fff;
    text-transform: uppercase;
    width: 70px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    z-index: 100;
    border-radius: 0 180px 180px 0;
    background: green;


  }

  &--invert {
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    color: #fff;
    text-transform: uppercase;
    width: 70px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    z-index: 100;
    border-radius: 180px 0 0 180px;
    background: red;

    &.hide {
      display: none;
    }
  }
}

* {
  outline: none !important;
}

h1 {
  padding-top: 103px;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: $w_black;

  span {
    font-size: 30px;
    font-weight: $w_regular;
    text-transform: none;
    width: 250px;
    vertical-align: top;
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    color: #fff;

    span {
      text-align: left;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 40px;

    span {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  @media screen and (max-width: 380px) {
    font-size: 30px;
  }
}

h2 {
  padding-top: 75px;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  font-weight: $w_light;
  padding-bottom: 20px;

  strong {
    font-weight: $w_black;
    color: $c_orange;
  }

  @media screen and (max-width: 768px) {
    padding-top: 30px;
    font-size: 30px;
  }
}

h3 {
  font-size: 30px;
  font-weight: $w_black;
}

h4 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: $w_black;
}

// .header
.header {
  background: url(../img/bg-header.jpg)50% 0 no-repeat;
  min-height: 770px;

  @media screen and (max-width: 768px) {
    background-position: 100% 0;
    background-size: cover;
    padding-bottom: 30px;
  }

  &--logo {
    width: span(2);
    float: left;
    box-sizing: border-box;
    padding-left: 15px;
    padding-top: 20px;

    @media screen and (max-width: 977px) {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding-left: 0;
      padding-top: 10px;
      text-align: center;

      img {
        width: 100px;
      }
    }
  }

  &--slogan {
    padding-top: 60px;
    float: left;
    width: span(4);
    line-height: 1.5;
    box-sizing: border-box;

    @media screen and (max-width: 977px) {
      padding-left: 20px;

      br {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding-top: 10px;
      max-width: 360px;
      float: none;
      margin: auto;
      text-align: center;
      color: #fff;
    }
  }

  &--contacts {
    padding-top: 59px;
    box-sizing: border-box;
    padding-left: 44px;
    width: span(6);
    float: left;

    @media screen and (max-width: 1154px) {
      float: right;
      width: 310px;
      padding-top: 30px;
      padding-left: 0;
      padding-right: 30px;
    }

    @media screen and (max-width: 768px) {
      float: none;
      margin: auto;
      padding-top: 10px;
      text-align: center;
      padding-right: 0;
    }
  }

  &--tel {
    display: inline-block;
    color: #fff;

    a {
      color: #fff;
      font-size: 22px;
      font-weight: $w_extra-bold;
      display: block;
    }

    span {
      display: inline-block;
    }

    &:before {
      margin-top: 3px;
      vertical-align: top;
      margin-right: 15px;
      content: '';
      display: inline-block;
      background: url(../img/icon-phone.svg);
      width: 41px;
      height: 41px;
    }

    @media screen and (max-width: 1154px) {
      padding-bottom: 15px;
    }
  }

  &--shedule {
    display: inline-block;
    color: #fff;
    padding-left: 30px;

    a {
      color: $c_orange;
      display: block;
      font-weight: $w_bold;
      text-decoration: underline;
    }

    @media screen and (max-width: 1154px) {
      text-align: right;
      display: block;
    }

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  &--tooltips {
    background: url(../img/headr--tooltips.png);
    width: 418px;
    height: 269px;
    box-sizing: border-box;
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 24px;
    font-weight: $w_extra-bold;
    text-transform: uppercase;

    p {
      padding-left: 30px;
    }

    a {
      font-size: 30px;
      font-weight: $w_extra-bold;
      color: #fff;
      margin-top: 10px;
      display: inline-block;
      border-bottom: 2px solid #fff;
      margin-left: 30px;
    }

    @media screen and (max-width: 768px) {
      margin: auto;
    }

    @media screen and (max-width: 450px) {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      background-size: cover;

      p {
        padding: 0;
      }

      a {
        font-size: 20px;
        padding: 0;
        margin-left: 0;
      }
    }
  }

  .formbox {
    margin-top: 46px;
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      margin: auto;
      padding-top: 20px;
    }
  }
}

// .nav
.nav {
  opacity: 0;
  height: 0;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 5px 20px rgba(#000, .1);
  transition-duration: .3s;

  &--btn {
    display: none;
    margin-right: 15px;
    border: 1px solid $c_black;
    width: 50px;
    height: 35px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 5px;

    span {
      display: block;
      height: 2px;
      margin: 6px;
      background: $c_black;
    }

    @media screen and (max-width: 1150px) {
      float: right;
      display: block;
    }
  }

  &.scroll-to-fixed-fixed {
    opacity: 1;
    height: auto;
    padding-top: 20px;
    padding-bottom: 10px;

    @media screen and (max-width: 1150px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  &--logo {
    width: span(1);
    float: left;

    @media screen and (max-width: 1150px) {
      width: span(3);
      box-sizing: border-box;
      padding-left: 10px;
    }

    @media screen and (max-width: 740px) {
      width: span(1);
    }
  }

  &--nav {
    width: span(8);
    float: left;
    padding-top: 25px;

    li {
      display: inline-block;
      margin-left: 20px;

      a {
        font-weight: $w_bold;
        color: $c_black;
      }
    }

    @media screen and (max-width: 1150px) {
      width: span(2);
      float: right;
      padding-top: 15px;

      ul {
        display: none;

        &.open {
          display: block;
          background: #fff;
          width: 100%;
          left: 0;
          top: 80px;
          position: fixed;

          li {
            display: block;
            text-align: center;

            a {
              display: block;
              padding-top: 15px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 740px) {
      width: span(1);
    }
  }

  &--tel {
    padding-top: 10px;
    width: span(3);
    float: left;

    a {
      color: $c_black;
      font-size: 22px;
      font-weight: $w_extra-bold;
      display: block;
    }

    span {
      display: inline-block;
    }

    &:before {
      margin-top: 3px;
      vertical-align: top;
      margin-right: 15px;
      content: '';
      display: inline-block;
      background: url(../img/icon-phone.svg);
      width: 41px;
      height: 41px;
    }

    @media screen and (max-width: 1150px) {
      width: span(5);
      text-align: right;
      box-sizing: border-box;
      padding-right: 10px;
    }

    @media screen and (max-width: 740px) {
      width: span(10);
      text-align: center;
    }

    @media screen and (max-width: 470px) {
      &:before {
        display: none;
      }
    }

    @media screen and (max-width: 400px) {
      display: none;
    }
  }
}

// .checkIcon
.checkIcon {
  border-bottom: 1px solid #E5E5E5;

  &--item {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    height: 80px;
  }

  &--icon {
    width: 40px;
    height: 40px;
    margin: auto;
    padding-bottom: 10px;
  }

  &--text {
    font-size: 16px;
    font-weight: $w_bold;
  }
}

// smeta
.smeta {
  background: url(../img/bg-smeta.jpg)50% 0 no-repeat;
  min-height: 616px;

  @media screen and (max-width: 768px) {
    min-height: auto;
  }

  @media screen and (max-width: 1020px) {
    box-sizing: content-box;
  }

  @media screen and (max-width: 768px) {
    background-position: 0 0;
  }

  h2 {
    text-align: left;
    padding-bottom: 5px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  &--text {
    font-size: 22px;
    padding-bottom: 35px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  &--item {
    padding-bottom: 30px;

    @media screen and (max-width: 768px) {
      max-width: 450px;
      margin: auto;
    }

    @media screen and (max-width: 440px) {
      padding-bottom: 40px;
    }
  }

  &--clock {
    vertical-align: middle;
    width: 47px;
    display: table-cell;

    img {
      width: 47px;
      height: 47px;
    }

    @media screen and (max-width: 440px) {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0;
    }
  }

  &--title {
    padding-top: 5px;
    vertical-align: middle;
    font-weight: $w_bold;
    display: table-cell;
    width: 230px;
    font-size: 16px;
    padding-left: 10px;
    line-height: 1.2;
    text-transform: uppercase;
    vertical-align: top;

    @media screen and (max-width: 440px) {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0;
    }
  }

  &--smile {
    vertical-align: middle;
    display: table-cell;

    @media screen and (max-width: 440px) {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0;
    }
  }

  &--number {
    font-size: 46px;
    font-weight: $w_black;
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;

    @media screen and (max-width: 440px) {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0;
    }
  }

  &--units {
    padding-left: 10px;
    vertical-align: middle;
    font-size: 24px;
    display: table-cell;

    @media screen and (max-width: 440px) {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0;
    }
  }

  .red {
    color: #F15800;
  }

  .green {
    color: #8FBB41;
  }

  &--btn {
    max-width: 390px;

    .btn {
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      margin: auto;
    }
  }

  &--name {
    padding-right: 30px;
    float: right;
    text-align: right;
    width: 150px;
    font-size: 16px;

    b {
      text-transform: uppercase;
      display: block;
      font-weight: $w_bold;
      font-size: 18px;
    }

    @media screen and (max-width: 1010px) {
      margin-top: -55px;
    }

    @media screen and (max-width: 893px) {
      display: none;
    }
  }
}

// .bestPrice
.bestPrice {
  background: url(../img/bg-bestPrice.jpg)50% 0 no-repeat;
  min-height: 759px;

  @media screen and (max-width: 993px) {
    background-size: cover;
    padding-bottom: 30px;
  }

  h2 {
    color: #fff;
    padding-bottom: 40px;
  }

  &--item {
    margin-top: 50px;
    background: #fff;
    overflow: hidden;
    border-radius: $bdrs;
    text-align: center;

    &-gold {
      margin-top: 0;
      border: 5px solid #FFBA00;

      @media screen and (max-width: 993px) {
        margin-top: 30px;
      }
    }

    @media screen and (max-width: 993px) {
      margin-top: 30px;
    }
  }

  &--title {
    text-transform: uppercase;
    background: #EFEFEF;
    line-height: 1;
    font-size: 24px;
    font-weight: $w_black;

    &-gold {
      background: #fcc300;
      background: -moz-linear-gradient(top, #fcc300 0%, #ffae00 100%);
      background: -webkit-linear-gradient(top, #fcc300 0%, #ffae00 100%);
      background: linear-gradient(to bottom, #fcc300 0%, #ffae00 100%);
      box-shadow: 0 5px 20px rgba(#000, .1);
    }

    &-inline {
      display: inline-block;
    }

    &-cell {
      height: 60px;
      vertical-align: middle;
      display: table-cell;
    }
  }

  &--row {
    border-bottom: 1px solid #E7E7E7;
  }

  &--cell-l {
    text-transform: uppercase;
    width: 180px;
    box-sizing: border-box;
    padding-left: 25px;
    height: 70px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 15px;

    @media screen and (max-width: 993px) {
      width: 350px;
    }
  }

  &--cell-r {
    font-size: 30px;
    font-weight: $w_bold;
    width: 220px;
    box-sizing: border-box;
    text-align: right;
    padding-right: 25px;
    height: 70px;
    display: table-cell;
    vertical-align: middle;

    b {
      text-transform: uppercase;
      font-size: 22px;
    }

    span {
      font-size: 18px;
    }

    img {
      width: 30px;
      height: 30px;
    }

    @media screen and (max-width: 993px) {
      width: 350px;
    }
  }

  .shortw {
    width: 140px;
  }

  &--btn {
    padding: 0 15px 20px 15px;

    .btn {
      font-size: 16px;
    }
  }
}

// .door
.window {
  h2 {
    padding-top: 60px;
    padding-bottom: 13px;
  }
  &--indiv{
    font-size: 22px;
    strong{
      font-weight: bold;
      color: #FCBC00;
    }
  }
  .windowtext {
    text-align: center;
    font-size: 22px;
    padding-bottom: 34px;
  }

  ul.tabs {
    text-align: center;
    padding-bottom: 10px;

    li {
      display: inline-block;
      border: 1px solid #E5E5E5;
      border-radius: 180px;
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: $w_black;
      text-transform: uppercase;
      margin: 0 5px;
      padding: 0 40px;

      @media screen and (max-width: 650px) {
        margin-bottom: 15px;
      }

      &.active {
        box-shadow: 0 5px 20px rgba(#000, .1);

        a {
          color: $c_orange;
          border-bottom: 0;
        }
      }

      a {
        color: $c_black;
        border-bottom: 1px dashed $c_black;
      }
    }
  }

  &--item {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 59px;
    padding-top: 49px;
  }

  &--labelwr {
    position: absolute;
    left: 0;
    top: -45px;
  }

  &--label {
    box-sizing: border-box;
    position: relative;
    top: 0;
    font-size: 14px;
    font-weight: $w_black;
    text-transform: uppercase;
    padding-top: 25px;
    text-align: center;
    width: 118px;
    height: 135px;
    text-shadow: 0 1px rgba(#fff, 0.4);
  }

  &--label1 {
    padding-top: 35px;
    left: -18px;
    background: url(../img/label1.png) no-repeat;
  }

  &--label2 {
    padding-top: 30px;
    left: 83px;
    position: absolute;
    text-shadow: 0 1px rgba(#000, 0.4);
    color: #fff;
    text-align: center;
    background: url(../img/label2.png) no-repeat;

    b {
      font-size: 24px;
      display: block;
    }
  }

  &--img {
    position: relative;
    margin-top: 40px;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    width: 100%;
    height: 335px;
    text-align: center;
    margin-bottom: 35px;

    img {
      max-width: 100%;
    }
  }

  h3 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 900;
  }

  p {
    padding-top: 20px;
    font-size: 18px;
  }

  &--gauger {
    width: 100px;
    margin-left: 110px;
    margin-top: 6px;
    padding-bottom: 22px;
    padding-left: 128px;
    background: url(../img/gauger.jpg) no-repeat;

    a {
      display: inline-block;
      padding-top: 40px;
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 900;
      color: #ffa800;
    }

  }

  &--text {
    width: 75%;

    float: left;
  }

  &--logo {
    width: 25%;
    margin-top: -40px;
    text-align: center;
    float: left;

    &-t {
      display: table-cell;
      width: 100%;
      height: 130px;
    }

    &-c {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 130px;
    }
  }

  &--data {
    margin-top: 86px;
    padding-left: 25px;
  }

  &--colors {
    padding-top: 10px;
  }

  &--price {
    margin-top: 27px;
    position: relative;
    padding: 25px;
    border: 6px solid $c_orange;
    border-radius: 5px;
    background: url(../img/3day.jpg) 95% 28px no-repeat;
    sub{
      font-size: .5em;
      margin-top: -10px;
      display: inline-block;
      vertical-align: middle;
    }
    del {

      color: #acacac;
      font-size: 22px;
    }

    &--bl1 {
      width: 145px;
      width: 175px;
      font-size: 22px;
      display: table-cell;

      @media screen and (max-width: 435px) {
        display: block;
        text-align: center;
        padding: 0;
        width: 100%;
      }
    }

    &--bl2 {
      font-size: 18px;
      display: table-cell;
      font-weight: $w_bold;

      p {
        padding-top: 10px;
      }

      @media screen and (max-width: 435px) {
        display: block;
        text-align: center;
        padding: 0;
        width: 100%;
      }

      strong {
        font-weight: $c_black;
        font-size: 30px;
      }
    }
  }

  &--btn {
    padding-top: 20px;
    max-width: 290px;
    margin: auto;

    .btn {
      height: 60px;
      line-height: 60px;
      margin: 0;
      margin-bottom: -59px;
    }
  }

  #tab2 {
    display: none;
  }

  #tab3 {
    display: none;
  }
}

// .calc
.calc {
  background: url(../img/bg_calc.jpg) 50% 0 no-repeat;
  min-height: 929px;

  @media screen and (max-width: 993px) {
    background-size: cover;
    padding-bottom: 30px;
  }

  h2 {
    padding-top: 80px;
    text-align: left;
    padding-bottom: 5px;

    @media screen and (max-width: 993px) {
      text-align: center;
      padding-top: 30px;
    }
  }

  label {
    font-size: 18px;
    font-weight: $w_bold;
  }

  &--text1 {
    font-size: 22px;
    padding-bottom: 28px;

    @media screen and (max-width: 993px) {
      text-align: center;
    }
  }

  &--doortype {
    padding-bottom: 26px;

    @media screen and (max-width: 993px) {
      text-align: center;
    }

    @media screen and (max-width: 495px) {
      width: 200px;
      margin: auto;
      text-align: left;
      padding-bottom: 15px;
    }

    &--item {
      min-width: 195px;
      display: inline-block;

      label {
        cursor: pointer;
        position: relative;
        padding-left: 31px;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: block;
          width: 21px;
          height: 21px;
          border-radius: 180px;
          vertical-align: middle;
          box-sizing: border-box;
          border: 2px solid #CECECE;
        }

        &:after {
          transition-duration: .3s;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: block;
          margin: 5px;
          width: 11px;
          height: 11px;
          border-radius: 180px;
          vertical-align: middle;
          box-sizing: border-box;
          background: $c_orange;
        }
      }

      input:checked+label:after {
        opacity: 1;
      }

      @media screen and (max-width: 495px) {
        margin-bottom: 10px;
      }
    }
  }

  &--slider {
    padding-bottom: 26px;
    max-width: 488px;

    label {
      display: block;
      padding-bottom: 15px;

      @media screen and (max-width: 993px) {
        text-align: center;
      }
    }

    &-left {
      width: 162px;
      display: table-cell;
      font-size: 13px;
      font-weight: $w_bold;
      padding-top: 5px;
    }

    &-center {
      width: 162px;
      display: table-cell;
      padding-top: 5px;

      input {
        text-align: center;
        color: $c_orange;
        font-size: 13px;
        font-weight: $w_bold;
        border: 0;
        background: none;
        padding: 0;
        width: 100%;
      }
    }

    &-right {
      width: 162px;
      text-align: right;
      display: table-cell;
      font-size: 13px;
      font-weight: $w_bold;
      padding-top: 5px;
    }

    .ui-widget.ui-widget-content {
      border: 0 !important;
    }

    .ui-slider-horizontal {
      height: 10px !important;
    }

    .ui-widget-content {
      border-radius: 180px !important;
      background: $c_orange !important;
    }

    .ui-widget-header {
      border-radius: 180px !important;
      background: #E5E5E5 !important;
    }

    .ui-slider .ui-slider-handle {
      width: 20px !important;
      height: 20px !important;
      border-radius: 180px !important;
      box-sizing: border-box !important;
      border: 4px solid #C3C3C3 !important;
    }

    .ui-state-active {
      background: #E5E5E5 !important;
    }

    @media screen and (max-width: 993px) {
      margin: auto;
    }
  }

  &--doorinput {
    max-width: 600px;

    @media screen and (max-width: 993px) {
      margin: auto;
      max-width: 405px;
    }

    &--item {
      width: 180px;
      display: inline-block;
      margin-right: 30px;
      margin-bottom: 22px;

      label {
        display: block;
        padding-bottom: 5px;
        padding-left: 12px;
      }

      input[type="text"],
      input[type="tel"],
      input[type="email"] {
        font-family: 'proxima_nova';
        font-size: 16px;
        height: 50px;
        padding-left: 14px;
        border-radius: $bdrs;
        width: 100%;
        border: 0;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        box-shadow: 0 5px 20px rgba(#000, .1);
      }

      @media screen and (max-width: 993px) {
        margin-left: 10px;
        margin-right: 10px;
      }

      @media screen and (max-width: 440px) {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }

  &--doorcolor {
    padding-top: 10px;

    @media screen and (max-width: 993px) {
      text-align: center;
    }

    &--item {
      vertical-align: top;
      display: inline-block;
      text-align: center;
      width: 90px;
      margin-right: 10px;

      &--img {
        box-sizing: border-box;
        width: 64px;
        height: 64px;
        border-radius: 180px;
        margin: auto;
        overflow: hidden;
        transition-duration: .3s;

        img {
          width: 100%;
          height: auto;
        }
      }

      &--text {
        padding-top: 10px;
        text-transform: uppercase;
        font-size: 13px;
        color: #868686;
      }
    }

    input:checked+label .calc--doorcolor--item--img {
      border: 5px solid $c_orange;
    }
  }

  .formbox .btn {
    margin-top: 25px;
  }

  .formbox label {
    color: $c_black;
  }

  .formbox--item {
    padding-top: 8px;
  }

  .formbox label {
    padding-bottom: 4px;
    padding-left: 12px;
  }

  @media screen and (max-width: 993px) {
    .formbox {
      margin: auto;
    }
  }

  &--men {
    margin-top: 53px;
    margin-left: -50px;

    img {
      display: block;
    }

    @media screen and (max-width: 993px) {
      display: none;
    }
  }

  &--btn {
    margin-top: -10px;
    max-width: 390px;
    margin-left: -72px;

    @media screen and (max-width: 993px) {
      margin: auto;
      margin-top: 30px;
    }
  }

  &--text2 {
    width: 600px;
    text-align: center;
    margin-left: -140px;
    font-size: 20px;
    font-weight: $w_light;
    padding-top: 75px;
    position: relative;

    @media screen and (max-width: 1079px) {
      margin-top: 60px;
    }

    @media screen and (max-width: 993px) {
      display: none;
    }

    &:before {
      display: block;
      position: absolute;
      top: 102px;
      left: -100px;
      content: 'ИЛИ';
      color: $c_orange;
      font-weight: $w_extra-bold;

      @media screen and (max-width: 1115px) {
        left: -50px;
      }
    }
  }
}

// .accessories
.accessories {
  background: url(../img/bg-accessories.jpg)50% 0 no-repeat;
  min-height: 677px;

  h2 {
    padding-top: 50px;
    margin-left: -45px;
  }

  @media screen and (max-width: 768px) {
    background-size: cover;
    background: none;
    min-height: auto;
    padding-bottom: 30px;
  }

  &--item {
    width: 48%;
    display: inline-block;
    text-align: center;

    @media screen and (max-width: 350px) {
      display: block;
      width: 100%;
    }
  }

  &--img {
    overflow: hidden;
    width: 120px;
    height: 120px;
    border-radius: 180px;
    border: 10px solid #fff;
    box-shadow: 0 5px 20px rgba(#000, .1);
    margin: auto;
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &--text {
    padding-top: 20px;
    font-weight: $w_bold;
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    .col-xs-12 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

// .cupon
.cupon {
  background: url(../img/bg-cupon.jpg) 50% 0 no-repeat;
  min-height: 559px;
  color: #fff;

  h2 {
    padding-top: 103px;
    padding-bottom: 13px;
  }

  p {
    font-size: 21px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    min-height: auto;
    padding-bottom: 30px;
  }

  h2 {
    text-align: left;
    color: #fff;

    strong {
      display: block;
    }

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  .formbox {
    width: 100%;
    max-width: 440px;
    text-align: center;
    padding-top: 15px;

    input[type="tel"] {
      box-shadow: none;
      width: 390px;
      display: block;
      border-radius: 35px 35px 0 0;
      height: 70px;
      text-align: center;
      padding: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .btn {
      text-align: center;
      box-sizing: border-box;
      width: 390px;
      margin: 0;
      border-radius: 0 0 35px 35px;
      padding: 0;
      color: $c_black;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .privacypolicy {
      text-align: left;
      margin-top: 24px;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      margin: auto;
    }
  }

  img {
    margin: 51px 0 0 -2px;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

// .works
.works {
  padding-bottom: 70px;

  h2 {
    padding-top: 56px;
    padding-bottom: 16px;
  }

  h4 {
    padding-bottom: 10px;
  }

  .worksitem {
    display: none;
    opacity: 0;
    transition-duration: .3s;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: block;
      opacity: 1;
    }
  }

  &--item {
    padding-bottom: 15px;
    margin-top: 18px;
    margin-bottom: 17px;
    text-align: center;
    box-shadow: 0 5px 20px rgba(#000, .1);
    min-height: 530px;
  }

  &--img {
    height: 249px;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }

  &--data {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
  }

  &--row {
    &:nth-child(even) {
      background: #F3F3F3;
    }
  }

  &--cell-l {
    font-size: 17px;
    height: 40px;
    vertical-align: middle;
    padding-left: 17px;
    width: 145px;
    box-sizing: border-box;
    display: table-cell;
    text-align: left;

    @media screen and (max-width: 993px) {
      width: 300px;
    }

    @media screen and (max-width: 768px) {
      width: 400px;
    }
  }

  &--cell-r {
    font-size: 18px;
    font-weight: $w_bold;
    height: 37px;
    vertical-align: middle;
    padding-right: 10px;
    width: 145px;
    box-sizing: border-box;
    display: table-cell;
    text-align: right;

    @media screen and (max-width: 993px) {
      width: 300px;
    }

    @media screen and (max-width: 768px) {
      width: 400px;
    }
  }

  &--btn {
    width: 325px;
    margin: 30px auto 0;

    .btn {
      height: 60px;
      line-height: 60px;
    }
  }
}

// .reviews
.reviews {
  padding-bottom: 85px;
  background: #F2F2F2;

  @media screen and (max-width: 1030px) {
    padding-bottom: 0;
  }

  h2 {
    padding-top: 70px;
    padding-bottom: 34px;
  }

  h3 {
    padding-bottom: 59px;
    padding-top: 50px;
    text-align: center;

    span {
      font-weight: $w_regular;
      display: block;
      font-size: 22px;
    }
  }

  &--wr--slider {
    margin: 0 16px;
    position: relative;

    @media screen and (max-width: 1250px) {
      padding-left: 50px;
      padding-right: 50px;
      box-sizing: border-box;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &--slider {
    background: #fff;
    padding: 25px 40px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(#000, .1);

    @media screen and (max-width: 768px) {
      padding: 10px;
    }

    &--nav {
      z-index: 5;
      width: 100%;
      position: absolute;
      top: 50%;
      margin-top: -18px;

      &-l {
        cursor: pointer;
        position: absolute;
        left: -53px;
      }

      &-r {
        cursor: pointer;
        position: absolute;
        right: -53px;
      }
    }
  }

  &--img {
    vertical-align: top;
    width: 220px;
    display: table-cell;

    @media screen and (max-width: 768px) {
      display: block;
      margin: auto;
    }
  }

  &--img2 {
    width: 220px;
    height: 220px;
    overflow: hidden;
    border-radius: 180px;
    border: 10px solid #F6F5F1;

    img {
      width: 100%;
      height: auto;
    }
  }

  &--text {
    padding-left: 40px;
    display: table-cell;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    &--name {
      text-transform: uppercase;
      padding-bottom: 5px;
      font-size: 24px;
      font-weight: $w_black;
      margin-top: 17px;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    &--age {
      padding-bottom: 15px;
      font-size: 18px;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    &--text {
      padding-left: 45px;
      padding-top: 10px;
      font-size: 18px;
      font-style: italic;
      background-image: url(../img/bloquote1.png), url(../img/bloquote2.png);
      background-repeat: no-repeat, no-repeat;
      background-position: 0 0, 100% 100%;

      @media screen and (max-width: 768px) {
        padding: 10px 45px;
      }
    }
  }

  &--rait {
    text-align: center;

    &--item {
      font-size: 90px;
      font-weight: $w_black;
      text-align: center;
      display: inline-block;
      width: 315px;
      border-radius: 15px;
      border: 5px solid #FFAE00;
      margin: 0 10px;
      color: #FFC000;
      line-height: .9;
      padding-bottom: 30px;

      @media screen and (max-width: 1030px) {
        margin-bottom: 45px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        box-sizing: border-box;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &--item3 {
      border: 5px solid #DCDCDC;
      color: #DCDCDC;
    }

    &--smile {
      color: $c_black;
      text-align: left;
      background: #F2F2F2;
      padding-left: 20px;
      padding: 20px;
      width: 195px;
      margin: -50px auto 14px;
      font-size: 18px;
      font-weight: $w_bold;

      b {
        padding-right: 10px;
        vertical-align: middle;
        display: table-cell;
      }

      span {
        vertical-align: middle;
        display: table-cell;
      }
    }
  }
}

// .stock
.stock {
  padding-bottom: 65px;
  text-align: center;

  h2 {
    padding-top: 79px;
    padding-bottom: 12px;
  }

  h4 {
    padding-top: 24px;
    padding-bottom: 10px;
  }

  &--item {
    box-shadow: 0 5px 20px rgba(#000, .1);
    margin-top: 30px;
    margin-bottom: 31px;
  }

  &--img {
    height: 248px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }

  &--text {
    height: 200px;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
  }

  &--descr {
    font-size: 14px;
    color: #888888;
    max-width: 700px;
    margin: auto;
  }
}

// .formblock
.formblock {
  padding-top: 50px;
  box-sizing: border-box;
  background: url(../img/bg-formblock.jpg)50% 0 no-repeat;

  @media screen and (max-width: 993px) {
    background-position: 0 0;
    box-sizing: content-box;
  }

  min-height: 708px;

  .formbox--text a {
    color: $c_orange;
    font-size: 22px;
    font-weight: $w_black;
  }

  img {
        padding-left: 112px;
    padding-top: 53px;
    max-width: 100%;
    box-sizing: content-box;

    @media screen and (max-width: 993px) {
      display: none;
    }
  }

  @media screen and (max-width: 993px) {
    .formbox {
      margin: auto;
    }
  }

  .formbox1 {
    margin-left: 42px;

    .formbox--item {
      padding-top: 11px;
    }

    textarea {
      height: 89px;
    }
  }
}

// .footer
.footer {
  padding-bottom: 5px;
  padding-top: 15px;
  border: 1px solid #EDEDED;

  &--logo {
    vertical-align: top;
    width: 150px;
    display: inline-block;
    img{
      max-width: 130px;
    }

    @media screen and (max-width: 993px) {
      width: 100%;
      display: block;
      float: left;
      text-align: center;
    }
  }

  &--data {
    padding-top: 10px;
    line-height: 1.5;
    display: inline-block;

    @media screen and (max-width: 993px) {
      width: 100%;
      display: block;
      float: left;
      text-align: center;
      padding-top: 10px;
    }
  }

  &--contacts {
    padding-top: 24px;
    box-sizing: border-box;

    @media screen and (max-width: 993px) {
      padding-top: 10px;
    }
  }

  &--tel {
    margin-left: 40px;
    vertical-align: top;
    display: inline-block;

    a {
      padding-top: 10px;
      color: $c_black;
      font-size: 22px;
      font-weight: $w_extra-bold;
      display: block;
    }

    span {
      display: inline-block;
    }

    &:before {
      margin-top: 3px;
      vertical-align: top;
      margin-right: 15px;
      content: '';
      display: inline-block;
      background: url(../img/icon-phone.svg);
      width: 41px;
      height: 41px;
    }

    @media screen and (max-width: 993px) {
      width: 100%;
      padding-top: 10px;
      text-align: center;
    }
  }

  &--shedule {
    display: inline-block;
    padding-left: 47px;

    a {
      color: $c_orange;
      display: block;
      font-weight: $w_bold;
      text-decoration: underline;
    }

    @media screen and (max-width: 993px) {
      width: 100%;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 30px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// .subFooter
.subFooter {
  padding-top: 25px;
  padding-bottom: 20px;

  .copywrite {
    color: #B5B5B5;
    font-size: 14px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  a {
    float: right;
    -o-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    transition-duration: .3s;

    &:hover {
      -o-filter: grayscale(0%);
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
    }

    @media screen and (max-width: 768px) {
      float: none;
      margin: auto;
      display: block;
      width: 130px;
      margin-top: 20px;
    }
  }
}

//.popup
.popup {
  background: #fff;
  margin: auto;
  position: relative;
  max-width: 490px;
  box-sizing: border-box;
  padding: 30px 50px;

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }

  .formbox--title {
    font-weight: $w_extra-bold;
    color: $c_black;
  }

  .formbox--text {
    color: $c_black;
  }

  label {
    color: $c_black;
  }

  .privacypolicy {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    color: $c_black;

    a {
      color: $c_black;
    }
  }

  .radio-el {
    text-align: left;
    padding-top: 20px;

    .radio {
      text-decoration: underline;
      padding: 0;
      display: inline-block;
      text-align: center;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: $w_regular;
      border: 1px solid #DBDBDB;
      border-radius: 3px;
      margin-bottom: 10px;
      transition-duration: .3s;
      cursor: pointer;
    }

    .radio1 {
      margin-right: 10px;
      width: 120px;
    }

    .radio2 {
      width: 150px;
    }

    input[type="radio"]:checked+label {
      border: 1px solid $c_orange;
      background: $c_orange;
      color: #fff;
      text-decoration: none;
    }
  }
}